
#phonenumber{
  width: 95%;
  padding: 8px;
  border: 2px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;


}


.submitbutton{

  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.2s;

}

h1{

  text-align: center;
  margin-bottom: 20px;
  font-size: 36px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
#email{
  width: 95%;
  padding: 8px;
  border: 2px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;






}
#full_name{
  width: 95%;
  padding: 8px;
  border: 2px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}
label{

  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  
  
}

form{


  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  font-size: 16px;
  color: #333;
  cursor: pointer;
}
option{


  margin-bottom: 20px;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  
  
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Basic styling for input */
input {
  padding: 8px 12px; /* Adjust padding as needed */
  border: 1px solid #ccc; /* Border color */
  border-radius: 4px; /* Rounded corners */
  font-size: 16px; /* Font size */
}

/* Hover and focus effects */
input:hover,
input:focus {
  border-color: #333; /* Change border color on hover/focus */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

/* Styling for disabled input */
input:disabled {
  background-color: #f7f7f7; /* Light gray background for disabled state */
  cursor: not-allowed; /* Change cursor on disabled input */
}
input[type="file"] {
  display: none;
}
.custom-file-upload {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.2s;

}

#message{


  width: 85%;
  padding: 28px;
  border: 2px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  text-align: right;
  
}

h3{

  text-align: center;
  margin-bottom: 20px;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);


}
table{

  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px; /* Adjust margin as needed */


}
th{

  background-color: #f2f2f2; /* Header background color */
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd; /* Header bottom border */

}
tr{


  padding: 8px;
  border-bottom: 1px solid #ddd; /* Rows bottom border */
  
}
body{

  background-image: url('C:\Users\Ido\Desktop\jlrlogo241.jpg'); /* Path to your image file */
  background-size: 70%;
  background-position: bottom;
  background-color: rgba(71, 64, 64, 0.7);
  height: 33vh;
}